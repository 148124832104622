import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import Skeleton from '@material-ui/lab/Skeleton';
import { Check, Clear, Error, ErrorOutlined, Info, Warning } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const LogsTable = props => {
  const {
    className,
    logs,
    handlePageChange,
    handleRowsPerPageChange,
    handleToggleAllLogs,
    handleToggleLog,
    handleOpenLog,
    selectedRowsIds,
    page,
    rowsPerPage,
    totalCount,
    loading,
    disableCard,
    paging,
    openedLog,
    ...rest
  } = props;

  const classes = useStyles();
  const table = (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormHelperText>
                <FormattedMessage id="connections.logs.selectAll" />
              </FormHelperText>
              <Checkbox
                onClick={() => handleToggleAllLogs()}
                disabled={logs.length === 0}
                checked={logs.filter(item => item.selectedRow).length === logs.length && logs.length !== 0}
                indeterminate={
                  logs.filter(item => item.selectedRow).length !== logs.length &&
                  logs.filter(item => item.selectedRow).length !== 0
                }
                inputProps={{ 'aria-label': 'all items selected' }}
                color="primary"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.logs.status" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.logs.logType" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.logs.message" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="connections.logs.createdAt" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            new Array(rowsPerPage)
              .fill(index => index)
              .map((value, index) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
          {!loading && logs.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>No logs found</TableCell>
            </TableRow>
          )}
          {!loading &&
            logs.length > 0 &&
            logs.map(log => (
              <TableRow key={log.mongoId}>
                <TableCell>
                  <Checkbox color="primary" checked={!!log.selectedRow} onChange={() => handleToggleLog(log)} />
                </TableCell>
                <TableCell>
                  <FormattedMessage id={`connections.logs.status.${log.status}`} />
                </TableCell>
                <TableCell>
                  {log.logLevel === 100 && <Info color="disabled" />}
                  {log.logLevel > 100 && log.logLevel < 300 && <Info color="secondary" />}
                  {log.logLevel >= 300 && log.logLevel < 400 && <Warning color="primary" />}
                  {log.logLevel >= 400 && log.logLevel < 500 && <ErrorOutlined color="primary" />}
                  {log.logLevel >= 500 && <Error color="error" />}
                </TableCell>
                <TableCell>
                  <Button variant="text" color="primary" onClick={() => handleOpenLog(log)}>
                    <span>
                      <FormattedMessage id={`packages.type.${log.packageType}`} />
                    </span>
                    <span>:&nbsp;</span>
                    <span>{log.message}</span>
                  </Button>
                </TableCell>
                <TableCell>
                  <FormattedDate value={log.createdAt} />
                  &nbsp;
                  <FormattedTime value={log.createdAt} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Dialog open={Boolean(openedLog)} onClose={() => handleOpenLog(null)} maxWidth="md" fullWidth>
        {openedLog && (
          <>
            <DialogTitle>
              <span>
                <FormattedMessage id={`packages.type.${openedLog.packageType}`} />
              </span>
              <span>:&nbsp;</span>
              <span>{openedLog.message}</span>
            </DialogTitle>
            <DialogContent>
              <Table>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>{openedLog.mongoId}</TableCell>
                </TableRow>
                {openedLog.packageType && (
                  <TableRow>
                    <TableCell>Package</TableCell>
                    <TableCell>
                      <FormattedMessage id={`packages.type.${openedLog.packageType}`} />
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>Message</TableCell>
                  <TableCell>{openedLog.message}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>
                    <FormattedMessage id={`connections.logs.status.${openedLog.status}`} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Logged at</TableCell>
                  <TableCell>
                    <FormattedDate value={openedLog.createdAt} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Notified</TableCell>
                  <TableCell>
                    {openedLog.notified && <Check />}
                    {!openedLog.notified && <Clear />}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Archived</TableCell>
                  <TableCell>
                    {openedLog.archived && <Check />}
                    {!openedLog.archived && <Clear />}
                  </TableCell>
                </TableRow>
                {openedLog.solution && (
                  <TableRow>
                    <TableCell>Probable solution</TableCell>
                    <TableCell>{openedLog.solution}</TableCell>
                  </TableRow>
                )}
                {openedLog.wiki && (
                  <TableRow>
                    <TableCell>Wiki</TableCell>
                    <TableCell>
                      <a href={openedLog.wiki} target="_blank" rel="noopener noreferrer">
                        {openedLog.wiki}
                      </a>
                    </TableCell>
                  </TableRow>
                )}
                {openedLog.internal && (
                  <TableRow>
                    <TableCell>Internal log</TableCell>
                    <TableCell>
                      <SyntaxHighlighter language="json" style={atomOneDark}>
                        {JSON.stringify(openedLog.internal, null, 2)}
                      </SyntaxHighlighter>
                    </TableCell>
                  </TableRow>
                )}
                {openedLog.request && (
                  <TableRow>
                    <TableCell>Request</TableCell>
                    <TableCell>
                      <SyntaxHighlighter language="json" style={atomOneDark}>
                        {JSON.stringify(openedLog.request, null, 2)}
                      </SyntaxHighlighter>
                    </TableCell>
                  </TableRow>
                )}
                {openedLog.response && (
                  <TableRow>
                    <TableCell>Response</TableCell>
                    <TableCell>
                      <SyntaxHighlighter language="json" style={atomOneDark}>
                        {JSON.stringify(openedLog.response, null, 2)}
                      </SyntaxHighlighter>
                    </TableCell>
                  </TableRow>
                )}
                {openedLog.extraData && (
                  <TableRow>
                    <TableCell>Extra data</TableCell>
                    <TableCell>
                      <SyntaxHighlighter language="json" style={atomOneDark}>
                        {JSON.stringify(openedLog.extraData, null, 2)}
                      </SyntaxHighlighter>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );

  if (disableCard) {
    return table;
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.center}>{table}</div>
        </PerfectScrollbar>
      </CardContent>
      {paging && (
        <CardActions className={classes.actions}>
          <TablePagination
            showFirstButton
            showLastButton
            component="div"
            count={totalCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </CardActions>
      )}
    </Card>
  );
};

LogsTable.propTypes = {
  className: PropTypes.string,
  paging: PropTypes.bool,
  logs: PropTypes.array.isRequired,
  openedLog: PropTypes.object,
};

LogsTable.defaultProps = {
  paging: true,
};

export default LogsTable;

import CardHeader from '@material-ui/core/CardHeader';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment/moment';
import Card from '@material-ui/core/Card';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  verticalGap: {
    gap: '1rem 0px',
  },
  statusIndicator: {
    display: 'inline-block',
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    alignSelf: 'center',
    backgroundColor: 'grey',
  },
  indicatorActive: {
    backgroundColor: 'green',
  },
  indicatorInactive: {
    backgroundColor: 'red',
  },
  textBold: {
    fontWeight: '700',
  },
  mr20px: {
    marginRight: '20px',
  }
}));

const ConnectionDetailsCard = props => {
  const { connection } = props;
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <Grid container xs={12} className={classes.verticalGap}>
          <Grid item xs={12} md={6} lg={4}>
            <Grid item xs={12} className={classes.mr20px}>
              <div>
                { connection ?
                  <>
                    <Typography className={classes.textBold} display="inline">
                      <FormattedMessage id="connections.active" />:&nbsp;
                    </Typography>
                    <div className={`${classes.statusIndicator} ${connection ? connection.active ? classes.indicatorActive : classes.indicatorInactive : '' }`}></div>
                  </>
                  : <Skeleton />}
              </div>
            </Grid>
            <Grid item xs={12} className={classes.mr20px}>
              { connection && connection.packages ?
                <>
                  <Typography className={classes.textBold} display="inline">
                    <FormattedMessage id="connections.packages" />: &nbsp;
                  </Typography>
                  <Typography display="inline">
                   { connection.packages.map((pkg, index) => {
                     return index === 0 ? <FormattedMessage id={`packages.type.${pkg.packageType}`}/> : ` - ${intl.formatMessage({id: `packages.type.${pkg.packageType}`})}`;
                     })
                   }
                  </Typography>
                </>
                : <Skeleton />}
            </Grid>
          </Grid>
          <Grid container xs={12} md={6} lg={4}>
            <Grid item xs={12} className={classes.mr20px}>
              { connection ?
                <>
                  <Typography className={classes.textBold} display="inline">
                    <FormattedMessage id="connections.syncStartDate" />:&nbsp;
                  </Typography>
                  <span>{connection && moment(connection.synchronizationStartDate).format('L')}</span>
                </>
                : <Skeleton />}
            </Grid>
            <Grid item xs={12} className={classes.mr20px}>
              { connection ?
                <>
                  <Typography className={classes.textBold} display="inline">
                    <FormattedMessage id="connections.createdAt" />:&nbsp;
                  </Typography>
                  <Typography display="inline">{connection && moment(connection.createdAt).format('L')}</Typography>
                </>
                : <Skeleton />}
            </Grid>
          </Grid>
          <Grid container xs={12} md={6} lg={4}>
            <Grid item xs={12} className={classes.mr20px}>
              <div>
                {connection && connection.organization.reseller ?
                  <>
                    <Typography className={classes.textBold} display="inline">
                      <FormattedMessage id="connections.reseller"/>:&nbsp;
                    </Typography>
                    <Typography display="inline">{connection.organization.reseller.organizationName}</Typography>
                  </>
                : <Skeleton />}
              </div>
            </Grid>
            <Grid item xs={12} className={classes.mr20px}>
              <div>
                {connection && connection.organization ?
                  <>
                    <Typography className={classes.textBold} display="inline">
                      <FormattedMessage id="connections.organization"/>:&nbsp;
                    </Typography>
                    <Typography display="inline">{connection.organization.organizationName}</Typography>
                  </>
                 : <Skeleton /> }
              </div>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ConnectionDetailsCard.propTypes = {
  connection: PropTypes.object.isRequired,
};

export default ConnectionDetailsCard;
